<template>
	<v-layout>
		<v-text-field v-model="filters.search" class="mx-2" flat single-line clearable :label="$t('client_workspace.tasks.textSearch')" prepend-icon="search" />
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'TasksManagerToolbarSearch',
	computed: {
		...mapState({
			filters: state => state.tasks.filters
		})
	}
}
</script>
